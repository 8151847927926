<template>
	<div class="card card-custom card-stretch gutter-b" :style="{ height: height + 'px', 'min-height': '250px' }" id="network-tree-container">
		<div class="card-header border-0 px-0 d-flex flex-column no-wrap" style="margin-top: 0.75rem; flex-wrap: nowrap;">
			<h3 class="card-title font-weight-bolder ml-9">
				<span class="card-label">Network Tree</span>
			</h3>
			<div v-if="loaded" class="card-body pt-2 h-100 overflow-auto">
				<div class="d-flex align-items-center">
					<div style="width: 100%">
						<div>
							<div v-for="(item, parent_index) in treeItems" :key="item.name + parent_index">
								<div class="d-flex align-items-center mb-2 text-nowrap" v-b-toggle="'collapse' + parent_index + item.name.replace(/ /g, '')">
									<i class="fa-chevron-right fa"></i>
									<div class="font-weight-bold"><img :src="item.icon" />{{ item.name }}</div>
								</div>
								<b-collapse :id="'collapse' + parent_index + item.name.replace(/ /g, '')" class="ml-3">
									<div v-for="(child, child_index) in item.children" :key="child.name + child_index">
										<div class="d-flex align-items-center mb-2">
											<i
												class="fa-chevron-right fa"
												v-if="!child.DID"
												@click="checkExpandNetwork(child.data.params, parent_index, child_index, child.name)"
												v-b-toggle="'collapse1' + child_index + child.name.replace(/ /g, '')"
											></i>
											<div class="d-flex align-items-center text-nowrap">
												<img :src="child.icon" height="17" />
												<a v-if="child.data" :href="child.data.link">{{ child.name }}</a>
												<span v-else>{{ child.name }}</span>
											</div>
										</div>
										<b-collapse :id="'collapse1' + child_index + child.name.replace(/ /g, '')" class="ml-3">
											<div v-for="(subchild, subchild_index) in child.children" :key="subchild.name + subchild_index">
												<div class="d-flex align-items-center mb-2">
													<i
														class="fa-chevron-right fa"
														v-if="!subchild.DID"
														v-b-toggle="'collapse2' + subchild_index + subchild.name.replace(/ /g, '')"
													></i>
													<div
														class="d-flex align-items-center text-nowrap"
														v-b-toggle="'collapse2' + subchild_index + subchild.name.replace(/ /g, '')"
													>
														<img :src="subchild.icon" height="15" />
														<a v-if="subchild.data" :href="subchild.data.link">{{ subchild.name }}</a>
														<span>{{ subchild.name }}</span>
													</div>
												</div>
												<b-collapse :id="'collapse2' + subchild_index + subchild.name.replace(/ /g, '')" class="ml-5">
													<div v-for="(sub2, s2Index) in subchild.children" :key="sub2.name + s2Index">
														<div class="d-flex align-items-center mb-1 text-nowrap">
															<img :src="sub2.icon" height="15" />
															<a v-if="sub2.data" :href="sub2.data.link">{{ sub2.name }}</a>
															<span v-else>{{ sub2.name }}</span>
														</div>
													</div>
												</b-collapse>
											</div>
										</b-collapse>
									</div>
								</b-collapse>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NetworkTreeview',
	data() {
		return {
			loaded: false,
			error: '',
			errorMsg: '',
			treeItems: [],
			treeExpandStatus: {},
			height: '100%',
		};
	},
	methods: {
		NetworkTreeViewData() {
			this.$http.get('idirect/networktreeview').then(res => {
				if (res.data.data.error === 404) {
					this.error = true;
					this.errorMsg = res.data.data.message;
				}
				if (res.data.data.hasOwnProperty('children')) {
					this.treeItems = res.data.data.children;
				} else {
					this.treeItems = res.data.data;
				}
				this.loaded = true;
				this.height = document.getElementById('network-tree-container').clientHeight;
			});
		},
        checkExpandNetwork(params, parent_index, index, child_name) {
            let id = parent_index+child_name;
            //track expanded status for expanded api call
            if(!id in this.treeExpandStatus || !this.treeExpandStatus[id]) {
                this.treeExpandStatus[id] = true;
                this.expandNetwork(params, parent_index, index);
            } else {
                this.treeExpandStatus[id] = false;
            } 
        },
		expandNetwork(params, parent_index, index) {
			this.$http.get(`idirect/networktreeview/inroutes/${params.server_id}/${params.network_id}`).then(res => {
				let treedata = this.treeItems[parent_index].children[index].children;
				res.data.data.forEach(item => {
					treedata.find((entry, i) => {
						if (entry.name === item.name) {
							this.treeItems[parent_index].children[index].children[i].children = item.children;
							this.treeItems = Object.assign({}, this.treeItems, this.treeItems);
							return true;
						}
					});
				});
			});
		},
	},
	mounted() {
		this.NetworkTreeViewData();
	},
};
</script>

<style scoped>
.fa-chevron-right.not-collapsed,
.not-collapsed .fa-chevron-right {
	transform: rotate(90deg);
	color: black;
}
.fa-chevron-right {
	transition: all 300ms linear;
	vertical-align: middle;
	margin-right: 0.5rem;
	color: #b5b5c3;
}
img {
	margin-right: 0.4rem;
	margin-bottom: 0.1rem;
}
</style>
